import { BriefProfile } from '../../types/profile/BriefProfile';
import { Role } from '../../types/profile/Profile';

export const FETCH_BRIEF_PROFILES = 'profile/FETCH_BRIEF_PROFILES';
export const FETCH_BRIEF_PROFILES_SUCCESS =
  'profile/FETCH_BRIEF_PROFILES_SUCCESS';
export const FETCH_SELF_BRIEF_PROFILE = 'profile/FETCH_SELF_BRIEF_PROFILE';
export const FETCH_SELF_BRIEF_PROFILE_SUCCESS =
  'profile/FETCH_SELF_BRIEF_PROFILE_SUCCESS';

export interface ProfileState {
  briefProfiles: { [key in Role | 'all']?: BriefProfile[] };
  selfBriefProfile?: BriefProfile;
}

const initialState: ProfileState = {
  briefProfiles: {},
  selfBriefProfile: undefined,
};

function profileReducer(state = initialState, action: any): ProfileState {
  switch (action.type) {
    case FETCH_BRIEF_PROFILES_SUCCESS:
      return {
        ...state,
        briefProfiles: {
          ...state.briefProfiles,
          [action.role]: action.briefProfiles,
        },
      };
    case FETCH_SELF_BRIEF_PROFILE_SUCCESS:
      return {
        ...state,
        selfBriefProfile: action.selfBriefProfile,
      };
    default:
      return state;
  }
}

export const fetchBriefProfiles = (role: Role | 'all') => ({
  type: FETCH_BRIEF_PROFILES,
  role,
});

export const fetchSelfBriefProfile = () => ({
  type: FETCH_SELF_BRIEF_PROFILE,
});

export default profileReducer;
