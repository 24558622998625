import { call, put, select, takeLatest } from 'redux-saga/effects';
import { superfetch } from '../../helper/fetchHelper';
import { FETCH_LOCATIONS, FETCH_LOCATIONS_SUCCESS } from './locationReducer';

function* fetchLocation(action: any) {
  const { isHardRefetch } = action;
  const existingValue: any = yield select((state) => state.location.list);
  if (!existingValue || isHardRefetch) {
    try {
      const response: Response = yield call(superfetch, `/api/locations`, {
        method: 'GET',
      });
      if (response.ok) {
        const json: any = yield response.json();
        yield put({
          type: FETCH_LOCATIONS_SUCCESS,
          value: json,
        });
      }
    } catch (e) {
      console.log(`Fetch locations failed.`);
    }
  }
}

export default function* locationSaga() {
  yield takeLatest(FETCH_LOCATIONS, fetchLocation);
}
