export const SET_PERSONAL_EDITING = 'editing/SET_PERSONAL_EDITING';
export const SET_GROUP_EDITING = 'editing/SET_GROUP_EDITING';
export const SET_COACH_EDITING = 'editing/SET_COACH_EDITING';
export const SET_PRICE_EDITING = 'editing/SET_PRICE_EDITING';
export const SET_SERVICE_EDITING = 'editing/SET_SERVICE_EDITING';

export interface EditingState {
  personal: boolean;
  group: boolean;
  coach: boolean;
  price: boolean;
  service: boolean;
}

const initialState: EditingState = {
  personal: false,
  group: false,
  coach: false,
  price: false,
  service: false,
};

export interface SetEditingAction {
  type:
    | typeof SET_PERSONAL_EDITING
    | typeof SET_GROUP_EDITING
    | typeof SET_COACH_EDITING
    | typeof SET_PRICE_EDITING
    | typeof SET_SERVICE_EDITING;
  editing: boolean;
}

export type EditingActionTypes = SetEditingAction;

function editingReducer(state = initialState, action: EditingActionTypes) {
  switch (action.type) {
    case SET_PERSONAL_EDITING:
      return {
        ...state,
        personal: action.editing,
      };
    case SET_GROUP_EDITING:
      return {
        ...state,
        group: action.editing,
      };
    case SET_COACH_EDITING:
      return {
        ...state,
        coach: action.editing,
      };
    case SET_PRICE_EDITING:
      return {
        ...state,
        price: action.editing,
      };
    case SET_SERVICE_EDITING:
      return {
        ...state,
        service: action.editing,
      };
    default:
      return state;
  }
}

export const setPersonalEditing = (editing: boolean): SetEditingAction => ({
  type: SET_PERSONAL_EDITING,
  editing,
});

export const setGroupEditing = (editing: boolean): SetEditingAction => ({
  type: SET_GROUP_EDITING,
  editing,
});

export const setCoachEditing = (editing: boolean): SetEditingAction => ({
  type: SET_COACH_EDITING,
  editing,
});

export const setPriceEditing = (editing: boolean): SetEditingAction => ({
  type: SET_PRICE_EDITING,
  editing,
});

export const setServiceEditing = (editing: boolean): SetEditingAction => ({
  type: SET_SERVICE_EDITING,
  editing,
});

export default editingReducer;
