export const SET_LOADING_BACKDROP = 'loadingBackdrop/SET_LOADING_BACKDROP';

export interface LoadingBackdropState {
  loading: boolean;
}

const initialState : LoadingBackdropState = {
  loading: false,
};

export interface SetLoadingBackdropAction {
  type: typeof SET_LOADING_BACKDROP;
  loading: boolean;
}

export type LoadingBackdropActionTypes = SetLoadingBackdropAction;

function loadingBackdropReducer(state = initialState, action: LoadingBackdropActionTypes) {
  switch (action.type) {
    case SET_LOADING_BACKDROP:
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
}

export const setLoadingBackdrop = (loading: boolean): SetLoadingBackdropAction => ({
  type: SET_LOADING_BACKDROP,
  loading,
});

export default loadingBackdropReducer;
