import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import logo from '../../../assets/img/brand/logo-166x106.png';

const Page403 = () => (
  <div className='app flex-row align-items-center'>
    <Container>
      <Row className='justify-content-center'>
        <Col md='6'>
          <div className='clearfix'>
            <img src={logo} className='mx-auto d-block' />
            <h4 className='pt-3 text-center'>你並沒有到訪此處的權限</h4>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Page403;
