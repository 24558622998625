import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { superfetch } from '../../helper/fetchHelper';
import { BriefProfile } from '../../types/profile/BriefProfile';
import { Role } from '../../types/profile/Profile';
import { FETCH_BRIEF_PROFILES, FETCH_BRIEF_PROFILES_SUCCESS, FETCH_SELF_BRIEF_PROFILE, FETCH_SELF_BRIEF_PROFILE_SUCCESS } from './profileReducer';

/**
 * @param {{ role: Role | 'all' }} action
 */
function* fetchBriefProfiles(action: any) {
  try {
    const query = action.role === 'all' ? '' : `?role=${action.role}`;
    const response: Response = yield call(superfetch, `/api/profiles/brief` + query, {
      method: 'GET',
    });
    if (response.ok) {
      const json: BriefProfile[] = yield response.json();
      yield put({
        type: FETCH_BRIEF_PROFILES_SUCCESS,
        role: action.role,
        briefProfiles: json,
      });
    }
  } catch (e) {
    console.log('Fetch briefProfiles failed.');
  }
}

function* fetchSelfBriefProfile(action: any) {
  try {
    const response: Response = yield call(superfetch, `/api/profiles/self-brief`);
    if (response.ok) {
      const json: BriefProfile = yield response.json();
      yield put({
        type: FETCH_SELF_BRIEF_PROFILE_SUCCESS,
        selfBriefProfile: json,
      });
    }
  } catch (e) {
    console.log('Fetch selfBriefProfile failed.');
  }
}

export default function* profileSaga() {
  yield takeEvery(FETCH_BRIEF_PROFILES, fetchBriefProfiles);
  yield takeLatest(FETCH_SELF_BRIEF_PROFILE, fetchSelfBriefProfile);
}
