import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import logo from '../../../assets/img/brand/logo-166x106.png';

const Page500 = () => (
  <div className='app flex-row align-items-center'>
    <Container>
      <Row className='justify-content-center'>
        <Col md='6'>
          <span className='clearfix'>
            {/* <h1 className="float-left display-3 mr-4">無法連線至伺服器</h1> */}
            <img src={logo} className='mx-auto d-block' />
            <h4 className='pt-3 text-center'>無法連線至伺服器😟</h4>
            <p className='text-muted text-center'>請重新載入</p>
          </span>
          {/* <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-search"></i>
                  </InputGroupText>
                </InputGroupAddon>
                <Input size="16" type="text" placeholder="What are you looking for?" />
                <InputGroupAddon addonType="append">
                  <Button color="info">Search</Button>
                </InputGroupAddon>
              </InputGroup> */}
        </Col>
      </Row>
    </Container>
  </div>
);

export default Page500;
