import { combineReducers } from 'redux';
import configurationReducer from './configuration/configurationReducer';
import editingReducer from './editing/editingReducer';
import loadingBackdropReducer from './loadingBackdrop/loadingBackdropReducer';
import profileReducer from './profile/profileReducer';
import userReducer from './user/userReducer';
import serviceReducer from './service/serviceReducer';
import locationReducer from './location/locationReducer';

const rootReducer = combineReducers({
  editing: editingReducer,
  user: userReducer,
  loadingBackdrop: loadingBackdropReducer,
  configuration: configurationReducer,
  profile: profileReducer,
  service: serviceReducer,
  location: locationReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
