import { useCallback, useEffect, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { history } from '../App';

const FETCH_INIT = 'FETCH_INIT';
const FETCH_SUCCESS = 'FETCH_SUCCESS';
const FETCH_FAILURE = 'FETCH_FAILURE';

interface FetchState {
  response: Response | null;
  loading: boolean;
  error: unknown;
}

interface FetchInitAction {
  type: typeof FETCH_INIT;
}

interface FetchSuccessAction {
  type: typeof FETCH_SUCCESS;
  response: Response | null;
}

interface FetchFailureAction {
  type: typeof FETCH_FAILURE;
  error: unknown;
}

type FetchActionTypes =
  | FetchInitAction
  | FetchSuccessAction
  | FetchFailureAction;

const dataFetchReducer = (
  state: FetchState,
  action: FetchActionTypes
): FetchState => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        response: action.response,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      throw new Error('Invalid fetching action type (in fetchHelper)');
  }
};

type RequestInitWithObjectBody = Omit<RequestInit, 'body'> & { body?: unknown };

export const useFetch = (): [Response | null, boolean, unknown, (url: RequestInfo, init?: RequestInitWithObjectBody | undefined) => void] => {
  const [state, dispatch] = useReducer(dataFetchReducer, {
    response: null,
    loading: false,
    error: null,
  });
  const history = useHistory();
  const isCanceled = useRef(false);

  useEffect(() => {
    return () => {
      isCanceled.current = true;
    };
  }, []);

  const callApi = async (url: RequestInfo, init?: RequestInitWithObjectBody | undefined) => {
    try {
      if (init === undefined) {
        init = {};
      }
      if (init.body) {
        init = {
          ...init,
          body: JSON.stringify(init.body),
          headers: {
            'Content-Type': 'application/json',
          },
        };
      }
      init.credentials = 'include';
      dispatch({ type: FETCH_INIT });

      const response = await fetch(url, init as RequestInit);

      if (response.status === 401) {
        history.push('/login');
      }
      if (!isCanceled.current) {
        dispatch({ type: FETCH_SUCCESS, response });
      }
    } catch (error) {
      if (!isCanceled.current) dispatch({ type: FETCH_FAILURE, error });
    }
  };

  return [state.response, state.loading, state.error, useCallback(callApi, [])];
};

// Fetch json

interface FetchJsonState<T> {
  response: T | null;
  loading: boolean;
  error: unknown;
}

interface FetchJsonInitAction {
  type: typeof FETCH_INIT;
}

interface FetchJsonSuccessAction<T> {
  type: typeof FETCH_SUCCESS;
  response: T | null;
}

interface FetchJsonFailureAction {
  type: typeof FETCH_FAILURE;
  error: unknown;
}

type FetchJsonActionTypes<T> =
  | FetchJsonInitAction
  | FetchJsonSuccessAction<T>
  | FetchJsonFailureAction;

const dataFetchJsonReducer = <T>(
  state: FetchJsonState<T>,
  action: FetchJsonActionTypes<T>
): FetchJsonState<T> => {
  switch (action.type) {
    case FETCH_INIT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        response: action.response,
      };
    case FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      throw new Error('Invalid fetching action type (in fetchHelper)');
  }
};

export const useFetchJson = <T = any>(
  successCallback?: (json: T) => void,
  failureCallback?: (error: unknown) => void
): [
  T | null,
  boolean,
  unknown,
  (url: RequestInfo, init?: RequestInitWithObjectBody | undefined) => void
] => {
  const [state, dispatch] = useReducer(
    (state: FetchJsonState<T>, action: FetchJsonActionTypes<T>) =>
      dataFetchJsonReducer(state, action),
    {
      response: null,
      loading: false,
      error: null,
    }
  );
  const history = useHistory();
  const isCanceled = useRef(false);

  useEffect(() => {
    return () => {
      isCanceled.current = true;
    };
  }, []);

  const callApi = async (url: RequestInfo, init?: RequestInitWithObjectBody | undefined) => {
    try {
      if (init === undefined) {
        init = {};
      }
      if (init.body) {
        init = {
          ...init,
          body: JSON.stringify(init.body),
          headers: {
            'Content-Type': 'application/json',
          },
        };
      }
      init.credentials = 'include';
      dispatch({ type: FETCH_INIT });

      const response = await fetch(url, init as RequestInit);

      if (response.status === 401) {
        history.push('/login');
      }
      if (!isCanceled.current) {
        const json = await response.json();
        if (response.ok) {
          successCallback && successCallback(json);
          dispatch({ type: FETCH_SUCCESS, response: json as T });
        } else {
          failureCallback && failureCallback(json);
          dispatch({ type: FETCH_FAILURE, error: json });
        }
      }
    } catch (error) {
      if (!isCanceled.current) dispatch({ type: FETCH_FAILURE, error });
    }
  };

  return [state.response, state.loading, state.error, useCallback(callApi, [])];
};

export const superfetch = async (url: RequestInfo, init?: RequestInit) => {
  if (init === undefined) {
    init = {};
  }
  if (init.body && !(init.body instanceof FormData)) {
    init.headers = {
      ...init.headers,
      'Content-Type': 'application/json',
    };
  }
  init.credentials = 'include';

  const response = await fetch(url, init);
  if (response.status === 401) {
    history.push('/login');
  }
  return response;
};
