import { User } from '../../types/profile/User';

export const SET_USER_INFO = 'user/SET_USER_INFO';
export const CLEAR_USER_INFO = 'user/CLEAR_USER_INFO';

export interface UserState {
  userInfo?: User;
}

const initialState: UserState = {};

export interface SetUserAction {
  type: typeof SET_USER_INFO;
  userInfo: User;
}
export interface ClearUserAction {
  type: typeof CLEAR_USER_INFO;
}

export type UserActionTypes = SetUserAction | ClearUserAction;

function userReducer(state = initialState, action: UserActionTypes) {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case CLEAR_USER_INFO:
      return { ...state, userInfo: undefined };
    default:
      return state;
  }
}

export const setUserInfo = (userInfo: User): SetUserAction => ({
  type: SET_USER_INFO,
  userInfo,
});
export const clearUserInfo = (): ClearUserAction => ({
  type: CLEAR_USER_INFO,
});

export default userReducer;
