import React from 'react';

const Loading = () => (
  <div className='pt-1 d-flex justify-content-center align-items-center'>
    <div className='mr-1'>
      <i className='fas fa-spinner fa-pulse'></i>
    </div>
    <div style={{ fontSize: 21 }}>載入中</div>
  </div>
);

export default Loading;
