import { AgeGroup } from '../../types/configuration/ageGroup';
import { ClientGroup } from '../../types/configuration/clientGroup';
import { ServiceType } from '../../types/configuration/serviceType';

export const FETCH_CONFIGURATION = 'configuration/FETCH_CONFIGURATION';
export const FETCH_CONFIGURATION_SUCCESS =
  'configuration/FETCH_CONFIGURATION_SUCCESS';

interface ConfigurationState {
  clientGroups: ClientGroup[] | null;
  ageGroups: AgeGroup[] | null;
  serviceTypes: ServiceType[] | null;
}

export type ConfigurationKey = keyof(ConfigurationState);

const initialState: ConfigurationState = {
  clientGroups: null,
  ageGroups: null,
  serviceTypes: null,
};

function configurationReducer(
  state = initialState,
  action: any
): ConfigurationState {
  switch (action.type) {
    case FETCH_CONFIGURATION_SUCCESS:
      return {
        ...state,
        [action.configurationKey]: action.value,
      };
    default:
      return state;
  }
}

export const fetchConfiguration = (configurationKey: ConfigurationKey, isHardRefetch: boolean = false) => ({
  type: FETCH_CONFIGURATION,
  configurationKey, isHardRefetch
});

export default configurationReducer;
