import { Location } from '../../types/config/Location';

export const FETCH_LOCATIONS = 'location/FETCH_LOCATIONS';
export const FETCH_LOCATIONS_SUCCESS = 'location/FETCH_LOCATIONS_SUCCESS';

interface LocationState {
  list: Location[] | null;
}

const initialState: LocationState = {
  list: null,
};

function locationReducer(state = initialState, action: any): LocationState {
  switch (action.type) {
    case FETCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        list: action.value,
      };
    default:
      return state;
  }
}

export const fetchLocations = (isHardRefetch: boolean = false) => ({
  type: FETCH_LOCATIONS,
  isHardRefetch,
});

export default locationReducer;
