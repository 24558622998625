import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useFetch, useFetchJson } from '../helper/fetchHelper';
import Page500 from '../views/Pages/Page500/Page500';
import Authenticating from './Authenticating';
import { useDispatch, useSelector } from 'react-redux';
import userReducer, { setUserInfo } from '../redux/user/userReducer';
import { SweetToast } from '../helper/sweetAlert';
import { Permission } from '../constants';
import Page403 from '../views/Pages/Page403/Page403';
import { AppState } from '../redux/rootReducer';
import { User } from '../types/profile/User';
import { fetchSelfBriefProfile } from '../redux/profile/profileReducer';

interface Props extends RouteProps {
  permissions: Permission[];
}

function PrivateRoute(props: Props) {
  const [response, loading, error, fetchApi] = useFetch();
  const dispatch = useDispatch();
  // const [allowed, setAllowed] = useState(false);
  const userInfo = useSelector<AppState, User | undefined>(
    (state) => state.user.userInfo
  );

  useEffect(() => {
    fetchApi('/api/auth');
    dispatch(fetchSelfBriefProfile());
  }, []);

  useEffect(() => {
    (async () => {
      if (response && response.ok) {
        try {
          const userInfo = await response.json();
          dispatch(setUserInfo(userInfo));
        } catch (e) {
          SweetToast.fire({
            icon: 'error',
            title: '無法取得帳戶號碼',
          });
        }
      }
    })();
  }, [response]);

  if (!response) {
    if (error) {
      console.log(error);
      return <Page500 />;
    } else {
      return <Authenticating />;
    }
  } else if (response.status === 401) {
    return <Redirect to='/login' />;
  } else if (
    !props.permissions.filter((value) => userInfo?.permissions?.includes(value))
  ) {
    return <Page403 />;
  } else if (!response.ok) {
    return <Page500 />;
  } else {
    return <Route {...props} />;
  }
}

export default PrivateRoute;
