import { call, put, takeEvery } from 'redux-saga/effects';
import { superfetch } from '../../helper/fetchHelper';
import { BriefService } from '../../types/service/BriefService';
import {
  FETCH_BRIEF_SERVICES,
  FETCH_BRIEF_SERVICES_SUCCESS,
} from './serviceReducer';

function* fetchBriefServices(action: any) {
  try {
    const response: Response = yield call(superfetch, `/api/services/brief`, {
      method: 'GET',
    });
    if (response.ok) {
      const json: BriefService[] = yield response.json();
      yield put({
        type: FETCH_BRIEF_SERVICES_SUCCESS,
        briefServices: json,
      });
    }
  } catch (e) {
    console.log('Fetch briefServices failed.');
  }
}

export default function* serviceSaga() {
  yield takeEvery(FETCH_BRIEF_SERVICES, fetchBriefServices);
}
