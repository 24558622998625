import { all } from 'redux-saga/effects';
import configurationSaga from './configuration/configurationSaga';
import profileSaga from './profile/profileSaga';
import serviceSaga from './service/serviceSaga';
import locationSaga from './location/locationSaga';

export default function* rootSaga() {
  yield all([
    configurationSaga(),
    profileSaga(),
    serviceSaga(),
    locationSaga(),
  ]);
}
