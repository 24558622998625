import { BriefService } from '../../types/service/BriefService';

export const FETCH_BRIEF_SERVICES = 'service/FETCH_BRIEF_SERVICES';
export const FETCH_BRIEF_SERVICES_SUCCESS =
  'service/FETCH_BRIEF_SERVICES_SUCCESS';

export interface ServiceState {
  briefServices: BriefService[];
}

const initialState: ServiceState = {
  briefServices: [],
};

function serviceReducer(state = initialState, action: any): ServiceState {
  switch (action.type) {
    case FETCH_BRIEF_SERVICES_SUCCESS:
      return {
        ...state,
        briefServices: action.briefServices,
      };
    default:
      return state;
  }
}

export const fetchBriefServices = () => ({
  type: FETCH_BRIEF_SERVICES,
});

export default serviceReducer;
